function Title() {
  return (
    <h1
      className="text-6xl font-bold mb-4 text-center"
      style={{ fontFamily: "'Caveat', cursive" }}
    >
      Dexie
    </h1>
  );
}

export default Title;
